body {
    font-family: 'Josefin Sans';
    background-color: #FFFAFB;
  }
  
  * {
    padding: 0px;
    margin: 0px;
    box-sizing: border-box;
  
  }
  
  ::-webkit-scrollbar {
    width: 0px;
  }
  
  .right-arrow,
  .left-arrow {
    cursor: pointer;
    background: transparent;
    outline: none;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #3120E0;
    font-size: 25px;
    border-radius: 4px;
    position: absolute;
    bottom: -20px;
    right: 80px;
    transform: scale(1, 1);
    transition: 0.3s all ease-in-out;
  }
  
  .left-arrow {
    right: 140px;
  }
  
  .right-arrow:hover,
  .left-arrow:hover {
    transform: scale(1.3, 1.3);
  }
  
  .react-horizontal-scrolling-menu--wrapper {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }
  
  .detail-image {
    width: 500px;
    height: 500px;
  }
  
  .pose-video {
    text-decoration: none;
  }
  .video-img{
    width: 300px;
  }

  .hero-banner-img {
    position: absolute;
    right: 40px;
    top: 0px;
    width: 700px;
    height: 900px;
    margin-top: -330px;
  
  }
  
  .pose-card {
    width: 400px;
    height: 445px;
    background: #fff;
    border-top: 4px solid #3B9AE1;
    border-bottom-left-radius: 20px;
    text-decoration: none;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding-bottom: 10px;
    transform: scale(1, 1);
    transition: 0.3s all ease-in-out;
  }
  
  .pose-card img {
    height: 326px;
  }
  
  .category-card {
    transform: scale(1, 1);
    transition: 0.3s all ease-in-out;
  }
  
  .pose-card:hover,
  .category-card:hover {
    transform: scale(1.1, 1.1);
    background: '#FFF'; 
    width: '270px'; 
    height: '282px';
    cursor: 'pointer'; 
    gap: '47px'; 
  }
  
  .search-btn:hover {
    color: #3120E0 !important;
    border: 1px solid #3120E0 !important;
  }
  
  .-video {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 387px;
    height: 381px;
    text-decoration: none;
  }
  
  @media screen and (max-width:1200px) {
    .detail-image {
      width: 250px;
      height: 250px;
    }
  
  
    .react-horizontal-scrolling-menu--scroll-container {
      width: 500px;
    }
  
    .left-arrow,
    .right-arrow {
      position: static !important;
    }
  
    .hero-banner-img {
      display: none;
    }
  
  }
  
  @media screen and (max-width:700px) {
   
    .hero-box-mobile {
      display: flex;
      padding: 0; 
      flex-direction: center; 
      justify-content: center;
     background-color: red;  }


    }
  